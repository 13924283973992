import req from "./request";
const qc = {
  image_url: "https://s.yishengxiangban.cn/", // 空间域名正式
};

const qnsdk = {
  async uploadHeader(name) {
    const data = await req.post("/admin/uploadToken", { filename: name });
    console.log("------", data);
    if (data.code == 200) {
      return data.data;
    }
  },
  getHost() {
    return "/upload/";
    // return qc.host;
  },
  buildUrl(data) {
    return qc.image_url + data;
  },
  async tinyEditorUploadImg(file, succFun, failFun) {
    var xhr, formData;
    // var file = blobInfo.blob(); //转化为易于理解的file对象
    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", qnsdk.getHost());
    const tokenData = await qnsdk.uploadHeader(file.name);
    xhr.onload = function () {
      if (xhr.status == 614) {
        succFun(qnsdk.buildUrl(tokenData.key));
        return;
      }
      var json;
      if (xhr.status != 200) {
        failFun("HTTP Error: " + xhr.status);
        return;
      }
      json = JSON.parse(xhr.responseText);
      if (!json || typeof json.key != "string") {
        failFun("Invalid JSON: " + xhr.responseText);
        return;
      }
      succFun(qnsdk.buildUrl(json.key));
    };
    formData = new FormData();

    formData.append("file", file, file.name); //此处与源文档不一样
    formData.append("token", tokenData.token);
    formData.append("key", tokenData.key);
    xhr.send(formData);
  },
  getDate(dayAgo) {
    const date = new Date();
    date.setDate(date.getDate() - dayAgo);
    const year = date.getFullYear().toString().padStart(4, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  },
  install(app) {
    app.component("g-upimg", () => import("../widgets/UploadFile.vue"));
    app.component("g-upvideo", () => import("../widgets/UploadVideo.vue"));
    app.component("g-upload", () => import("../widgets/UploadMedia.vue"));
    app.component("g-editor", () => import("../widgets/GEditor.vue"));
    app.component("g-status", () => import("../widgets/ChangeStatus.vue"));
    app.component("g-yuaninput", () => import("../widgets/YunInput.vue"));
    app.prototype.$qiniu = qnsdk;
    app.prototype.$getDate = qnsdk.getDate;
  },
};

export default qnsdk;
