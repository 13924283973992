import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: "/Stat/",
    meta: {
      reqauth: false,
      isMenu: false,
    },
  },
  {
    path: "/Stat/",
    name: "数据统计",
    component: () => import("../views/LayoutView.vue"),
    meta: {
      reqauth: true,
      isMenu: true,
      icon: "el-icon-data-analysis",
    },
    children: [
      {
        path: "",
        path1: "index",
        name: "全站统计",
        component: () => import("../views/Stat/IndexView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "order",
        path1: "order",
        name: "订单统计",
        component: () => import("../views/Stat/OrderView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "user",
        path1: "user",
        name: "用户统计",
        component: () => import("../views/Stat/UserView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "inout",
        path1: "inout",
        name: "财务统计",
        component: () => import("../views/Stat/InOutView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
    ],
  },
  {
    path: "/Admin/",
    name: "系统设置",
    component: () => import("../views/LayoutView.vue"),
    meta: {
      reqauth: true,
      isMenu: true,
      icon: "el-icon-setting",
    },
    children: [
      // {
      //   path: "",
      //   name: "管理员管理",
      //   component: () => import("../views/Admin/IndexView.vue"),
      //   meta: {
      //     reqauth: true,
      //     isMenu: true,
      //   },
      // },
      {
        path: "",
        path1: "appver",
        name: "app版本管理",
        component: () => import("../views/Admin/AppVerView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "changepass",
        name: "修改密码",
        component: () => import("../views/Admin/ChangePass.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "smsadmin",
        name: "短信设置",
        component: () => import("../views/Admin/SmsView.vue"),
        meta: {
          reqauth: true,
          isMenu: false,
        },
      },
      {
        path: "payadmin",
        name: "支付设置",
        component: () => import("../views/Admin/PayView.vue"),
        meta: {
          reqauth: true,
          isMenu: false,
        },
      },
      {
        path: "rechagreadmin",
        name: "充值设置",
        component: () => import("../views/Admin/RechargeView.vue"),
        meta: {
          reqauth: true,
          isMenu: false,
        },
      },
      {
        path: "kuaidi100",
        name: "快递100",
        component: () => import("../views/Admin/KuaiDi100View.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "icons",
        name: "图片设置",
        component: () => import("../views/Admin/IconView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "content",
        name: "基本信息",
        component: () => import("../views/Admin/ContentView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "cash",
        name: "资金设置",
        component: () => import("../views/Admin/CashView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "user",
        name: "用户设置",
        component: () => import("../views/Admin/UserSet.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
    ],
  },
  {
    path: "/Operation/",
    name: "运营管理",
    component: () => import("../views/LayoutView.vue"),
    meta: {
      reqauth: true,
      isMenu: true,
      icon: "el-icon-s-operation",
    },
    children: [
      {
        path: "",
        name: "首页管理",
        component: () => import("../views/Operation/IndexView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "lottery",
        name: "现金大转盘",
        component: () => import("../views/Operation/LotteryConf.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "lotterylog",
        name: "现金大转盘总计记录",
        component: () => import("../views/Operation/LotteryLog.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "page",
        name: "页面配置",
        component: () => import("../views/Operation/PageView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "feedback",
        name: "意见反馈",
        component: () => import("../views/Operation/FeedbackView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "evals",
        name: "订单评价",
        component: () => import("../views/Operation/EvalsView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
    ],
  },
  {
    path: "/Content/",
    name: "内容管理",
    component: () => import("../views/LayoutView.vue"),
    meta: {
      reqauth: true,
      isMenu: true,
      icon: "el-icon-postcard",
    },
    children: [
      {
        path: "",
        name: "金价管理",
        component: () => import("../views/Content/GoldPriceView..vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "article",
        name: "文章管理",
        component: () => import("../views/Content/ArticleView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "sharetemplate",
        name: "分享模版",
        component: () => import("../views/Content/ShareTemplate.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
    ],
  },
  {
    path: "/Goods/",
    name: "商品管理",
    component: () => import("../views/LayoutView.vue"),
    meta: {
      reqauth: true,
      isMenu: true,
      icon: "el-icon-s-goods",
    },
    children: [
      {
        path: "",
        name: "商品审核",
        component: () => import("../views/Goods/AduitView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "goods",
        name: "商品列表",
        component: () => import("../views/Goods/IndexView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "goodsseckill",
        name: "秒杀列表",
        component: () => import("../views/Goods/GoodsSeckill.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "recharge",
        name: "充值商品",
        component: () => import("../views/Goods/RechargeView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "category",
        name: "分类管理",
        component: () => import("../views/Goods/CategoryView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "model",
        name: "模型列表",
        component: () => import("../views/Goods/ModelView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "freight",
        name: "运费规则",
        component: () => import("../views/Goods/FreightView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },

      {
        path: "vendor",
        name: "供应商",
        component: () => import("../views/Goods/VendorView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
    ],
  },
  {
    path: "/Order/",
    name: "订单管理",
    component: () => import("../views/LayoutView.vue"),
    meta: {
      reqauth: true,
      isMenu: true,
      icon: "el-icon-s-order",
    },
    children: [
      {
        path: "",
        name: "订单列表",
        component: () => import("../views/Order/IndexView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "recharge",
        name: "充值订单",
        component: () => import("../views/Order/RechargeView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "agent",
        name: "升星星订单",
        component: () => import("../views/Order/StarView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "withdraw",
        name: "提现",
        component: () => import("../views/Order/WithdrawView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
    ],
  },
  {
    path: "/User/",
    name: "用户管理",
    component: () => import("../views/LayoutView.vue"),
    meta: {
      reqauth: true,
      isMenu: true,
      icon: "el-icon-s-custom",
    },
    children: [
      {
        path: "",
        name: "用户列表",
        component: () => import("../views/User/IndexView.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "starlog",
        name: "升星日志",
        component: () => import("../views/User/StarLog.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
      {
        path: "sviplog",
        name: "svip日志",
        component: () => import("../views/User/SvipLog.vue"),
        meta: {
          reqauth: true,
          isMenu: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      reqauth: false,
      isMenu: false,
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.reqauth && store.state.admin_id == 0) {
    window.localStorage.setItem("to_path", to.fullPath);
    // console.log("----", to, "-----", from);
    next("/login");
  }
  next();
});

export default router;
